<div class="tab-container">
  <div class="row g-0">
    <h1 class="my-5 text-center">
      <ng-container *ngIf="isHistory" i18n="@@order-list.history">Historik</ng-container>
      <ng-container *ngIf="!isHistory" i18n="@@order-list.orders">Beställningar</ng-container>
    </h1>
  </div>
  <div class="nav">
    <div class="nav-container me-auto" [style]="!isHistory ? {width: '100%'} : null">
      <ul class="nav-tabs mb-3">
        <li class="nav-item" *ngIf="!isHistory">
          <a class="nav-link" routerLink="/orders/ordercard" [queryParams]="{ ordertype: OrderType.AnyType }"
             [class.active]="ordertype === OrderType.AnyType" i18n="@@order-list.ongoing">Pågående</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="(isHistory ? '/history' : '/orders/ordercard')"
             [queryParams]="{ ordertype: OrderType.Unplanned }"
             [class.active]="ordertype === OrderType.Unplanned || ordertype === OrderType.Urgent"
             i18n="@@order-list.unplanned">Oplanerad</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="(isHistory ? '/history' : '/orders/ordercard')"
             [queryParams]="{ ordertype: OrderType.Planned }" [class.active]="ordertype === OrderType.Planned"
             i18n="@@order-list.planned">Planerad</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="(isHistory ? 'unboundorder' : '/unboundorders')"
             [class.active]="isUnboundOrder()" i18n="@@order-list.unbound-order">Friorder</a>
        </li>
      </ul>
      <div *ngIf="!isHistory" class="d-flex me-1" style="height: 38px">
        <button id="new-order-btn"
                *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.OperatorTrafikledare])"
                class="btn btn-primary ms-2 me-3" (click)="newOrder()" i18n="@@order-list.new-order"> Ny beställning
        </button>

        <div class="view-icons">
          <a routerLink="/orders/ordercard" class="btn btn-light me-1 active" role="button" data-bs-toggle="button"><i class="fa fa-th"></i></a>
          <a routerLink="/orders/" class="btn btn-light" role="button" data-bs-toggle="button"><i class="fa fa-bars"></i></a>
        </div>
      </div>
    </div>

    <app-date-filter *ngIf="isHistory" (dateRange)="onDateRangeChanged($event)" [dateOrigin]="dateOrigin" [defaultRangeOption]="dateFilterOptionEnum.Today" class="ms-2">
    </app-date-filter>

  </div>
</div>

<div class="d-flex flex-row flex-wrap card-row">
  <div *ngFor="let order of orders" class="card-col">
    <div class="card" routerLink="{{ getOrderLink(order.id, order.isHastusPlanned, order.isProductBased) }}">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <div class="tb-badge">{{ order.id }}</div>
          </div>
          <div class="col w-75">
            <div class="caption operator-name">{{order.operator.name}}</div>
            <div class="col text-truncate caption-lt-xs" *ngIf="order.tripTrainNumbers.length !== 0"
                 i18n="@@order-list.card-train-number">Tågnummer: {{ order.tripTrainNumbers }}</div>
            <div class="col text-truncate caption-lt-xs"
                 *ngIf="order.tripTrainNumbers.length === 0 && order.orderTrainNumber"
                 i18n="@@order-list.card-train-number">Tågnummer: {{ order.orderTrainNumber }}</div>
          </div>
        </div>
        <div *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare])
            && order?.orderActionState === OrderActionState.ActionNeeded">
          <button id="action-needed-btn" class="card-label white-on-red" tabindex="-1"
                  i18n="@@order-list.action-required">
            Kräver åtgärd
          </button>
        </div>
        <div *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare])
              && order?.orderActionState === OrderActionState.Ongoing">
          <button id="ongoing-btn" class="card-label white-on-yellow" tabindex="-1" i18n="@@order-list.is-ongoing">
            Under tillsättning
          </button>
        </div>

        <div *ngIf="!order.isHastusPlanned && !order.isProductBased" class="card-stops">
          <div class="row">
            <div class="col caption-lt-xs">
              <ng-container *ngIf="order.isRoundTrip" i18n="@@order-list.round-trip">Tur och retur</ng-container>
              <ng-container *ngIf="!order.isRoundTrip" i18n="@@order-list.one-way">Enkel</ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-5 caption">
              {{ order.startPlace }}
            </div>
            <div class="col-2 text-center">
              <img class="right-arrow" src="/assets/img/icons/right-arrow.png" alt="Icon right arrow">
            </div>
            <div class="col-5 caption">
              {{ order.endPlace }}
            </div>
          </div>
        </div>
        <div *ngIf="order.isProductBased">
          <div class="caption hastusplanned-label">
            <!-- Produktbaserad -->
          </div>
        </div>
        <div *ngIf="order.isHastusPlanned">
          <div class="caption hastusplanned-label" i18n="@@order-list.planned">
            Planerad
          </div>
        </div>
        <div class="card-times">
          <div class="row">
            <div class="col-5 caption">
              {{ order.startTripStop?.passTime || order.startTime | amDateFormat:"YYYY-MM-DD HH:mm" }}
            </div>
            <div class="col-2 text-center">
              <img *ngIf="order.endTripStop?.passTime || order.endTime" class="right-arrow"
                   src="/assets/img/icons/right-arrow.png" alt="Icon right arrow">
            </div>
            <div *ngIf="order.endTripStop?.passTime || order.endTime" class="col-5 caption-lt-bl">
              {{ order.endTripStop?.passTime || order.endTime | amDateFormat:"YYYY-MM-DD HH:mm" }}
            </div>
          </div>
        </div>
        <div *ngIf="order.referenceId" class="callout-block">
          <span class="callout"></span>
          <div class="caption-lt-xs" i18n="@@order-list.reference">Referens</div>
          <div class="caption">{{ order.referenceId }}</div>
        </div>
      </div>
      <div class="card-footer caption-lt">
        {{ order.currentState.stateDescription }} {{ order.currentState.updated | amTimeAgo }}
      </div>
    </div>
  </div>
</div>
