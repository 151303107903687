import { OrderType } from './order-type';
import { StateObject } from './state-object';
import { TripStopSummary } from './drive-order';
import { Driver } from './driver';
import { Vehicle } from './vehicle';
import { AvailableVehicle } from './availability-inquiry';

export class SubcontractorOrder {
  id: number;
  orderIsProductBased: boolean;
  orderRoute: SubcontractorOrderRoute;
  immediateStart: boolean;
  orderType: OrderType;
  orderId: number;
  orderTrainNumber: string;
  subcontractorId: number;
  subcontractorName: string;
  availabilityInquiryId: number;
  numberOfVehiclesAssigned: number;
  estimatedTimeOfArrivalMinutes: number;
  assignedVehicles: AvailableVehicle[];
  currentState: SubcontractorOrderStateObject;
  orderRouteName: string;
  startTime: Date;
  endTime: Date;
  startTripStop: TripStopSummary;
  endTripStop: TripStopSummary;
  orderReason: SubcontractorOrderReason;
  orderedByNobina: SubcontractorOrderStateObject;
  isRoundTrip: boolean;
  startOrderRouteStopDescription: string;
  endOrderRouteStopDescription: string;
}

export class SubcontractorOrderTrips {
  totalItems: number;
  items: SubcontractorOrderListItem[];
}

export class SubcontractorOrderListItem {
  id: number;
  orderRoute: SubcontractorOrderRoute;
  immediateStart: boolean;
  orderType: OrderType;
  orderId: number;
  tripId: number;
  orderTrainNumber: string;
  subcontractorId: number;
  subcontractorName: string;
  availabilityInquiryId: number;
  numberOfVehiclesAssigned: number;
  estimatedTimeOfArrivalMinutes: number;
  currentState: SubcontractorOrderStateObject;
  orderRouteName: string;
  startTime: Date;
  endTime: Date;
  startTripStop: TripStopSummary;
  endTripStop: TripStopSummary;
  orderReason: SubcontractorOrderReason;
  orderedByNobina: SubcontractorOrderStateObject;
  isRoundTrip: boolean;
  startOrderRouteStopDescription: string;
  endOrderRouteStopDescription: string;
  vehicle: Vehicle;
  driver: Driver;
}

export class SubcontractorOrderRoute {
  routeName: string;
  routeVariantName: string;
}

export class SubcontractorOrderReason {
  id: number;
  description: string;
  additionalInfo: string;
}

export class SubcontractorOrderRequest {
  orderId: number;
  subcontractorId: number;
  availabilityInquiryId: number;
  assignedVehicles: AvailableVehicle[];
  numberOfVehiclesAssigned: number;
  estimatedTimeOfArrivalMinutes: number;
}

export class SubcontractorOrderStateObject extends StateObject {

  public constructor(init?: Partial<SubcontractorOrderStateObject>) {
    super(init);
    Object.assign(this, init);
  }
}

export enum SubcontractorOrderState {
  OrderedByNobina = 0,
  Assigned = 1,
  Ongoing = 2,
  Completed = 3
}
