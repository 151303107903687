import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';
import { AuthService } from '../../../common/services/auth.service';
import { OutcomeState } from '../../../common/models/outcome-state';
import { OutcomeService } from '../../../common/services/outcome.service';
import { OperatorService } from '../../../common/services/operator.service';
import { SubcontractorService } from '../../../common/services/subcontractor.service';
import { Operator } from '../../../common/models/operator';
import { Subcontractor, SubcontractorVacantType } from '../../../common/models/subcontractor';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { I18n, CustomDatepickerI18n, DateTimePickerHelper } from '../../../common/datepicker-helpers';
import { UserRoles } from '../../../authentication/user-roles';
import { OrderByService } from '../../../common/services/order-by.service';

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrls: ['./create-report.component.scss'],
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})
export class CreateReportComponent implements OnInit {

  OutcomeState = OutcomeState;
  UserRoles = UserRoles;
  path: string;
  queryStates: number[];
  reportForm: UntypedFormGroup;
  operators: Operator[];
  subcontractors: Subcontractor[];
  now: Date = new Date();
  datepickerDefault: NgbDateStruct = { year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate() };
  buttonsDisabled = false;
  userIsNobinaLeveransansvarig = false;
  userIsOperatorTrafikledare = false;
  includeUnassignedDriveOrders = true;
  garageIds: string[] | string;
  userIsOperatorWithMultipleOrganisations: boolean;
  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private outcomeService: OutcomeService,
    private operatorService: OperatorService,
    private subcontractorService: SubcontractorService,
    private orderByService: OrderByService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['state']) {
        this.queryStates = [+params['state']];
      } else {
        this.queryStates = [OutcomeState.WaitingForApprovalByNobina, OutcomeState.WaitingForApprovalBySubcontractor];
      }
    });
    this.path = this.router.url;

    this.userIsNobinaLeveransansvarig = this.authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare]);
    this.userIsOperatorTrafikledare = this.authService.userHasAnyRole([UserRoles.OperatorTrafikledare]);
    this.garageIds = this.authService.user.idTokenClaims.garageIds as string[];

    if (this.userIsOperatorTrafikledare) {
      this.userIsOperatorWithMultipleOrganisations = this.garageIds.length > 1;
    }

    if (this.userIsNobinaLeveransansvarig || this.userIsOperatorTrafikledare) {
      this.getOperatorsAndSubcontractors();
    }

    this.createForm();
  }

  get operatorsCtrl() { return this.reportForm.get('selectedOperatorIds'); }
  get subcontractorsCtrl() { return this.reportForm.get('selectedSubcontractorIds'); }
  get startDateCtrl() { return this.reportForm.get('startDate'); }
  get endDateCtrl() { return this.reportForm.get('endDate'); }

  createForm() {
    if (this.userIsNobinaLeveransansvarig) {
      this.reportForm = this.fb.group({
        startDate: this.datepickerDefault,
        endDate: this.datepickerDefault,
        selectedOperatorIds: [null, Validators.required],
        selectedSubcontractorIds: [null, Validators.required]
      });
    } else if (this.userIsOperatorTrafikledare && this.userIsOperatorWithMultipleOrganisations) {
      this.reportForm = this.fb.group({
        startDate: this.datepickerDefault,
        endDate: this.datepickerDefault,
        selectedOperatorIds: [null, Validators.required],
      });
    } else {
      this.reportForm = this.fb.group({
        startDate: this.datepickerDefault,
        endDate: this.datepickerDefault,
      });
    }
  }

  getOperatorsAndSubcontractors() {
    this.operatorService.getOperators(true)
      .subscribe(ops => {
        this.operators = this.orderByService.transform(ops.body, 'name');
        if (!!this.reportForm && !!this.operatorsCtrl) {
          this.operatorsCtrl.patchValue(this.operators.map(x => x.operatorOrganisationId)); // Select all
        }
        if (this.userIsNobinaLeveransansvarig) {
          this.getSubcontractors(this.operators);
        }
      });
  }

  selectAllOperators(selectComp: NgSelectComponent) {
    this.operatorsCtrl.patchValue(this.operators.map(x => x.operatorOrganisationId));
    selectComp.close();
  }

  getSubcontractors(ops: Operator[]) {
    const operatorId = ops.length === 1 ? ops[0].id : null;
    this.subcontractorService.getSubcontractors(operatorId)
      .subscribe(s => {
        // filter subcontractors by the users operators and remove vakant
        this.subcontractors = s.body.filter(x => x.operators.map(y => y.id).some(o => ops.map(p => p.id).includes(o)) && x.subcontractorVacantType === SubcontractorVacantType.None);
        this.subcontractors = this.orderByService.transform(this.subcontractors, 'name');
        if (!!this.reportForm && !!this.subcontractorsCtrl) {
          this.subcontractorsCtrl.patchValue(this.subcontractors.map(x => x.id));  // Select all
        }
      });
  }

  toggleIncludeUnassignedDriveOrders(includeUnassignedDriveOrders: boolean) {
    this.includeUnassignedDriveOrders = !includeUnassignedDriveOrders;
  }

  selectAllSubcontractors(selectComp: NgSelectComponent) {
    this.subcontractorsCtrl.patchValue(this.subcontractors.map(x => x.id));
    selectComp.close();
  }

  onDateSelection() {
    const startDate = DateTimePickerHelper.getSelectedDateTime(this.startDateCtrl.value, 0, 0);
    const endDate = DateTimePickerHelper.getSelectedDateTime(this.endDateCtrl.value, 0, 0);
    if (endDate < startDate) {
      this.endDateCtrl.setErrors({ 'invalid': true });
    } else {
      this.endDateCtrl.setErrors(null);
    }
  }

  createReport() {
    // trigger validation on all fields
    Object.keys(this.reportForm.controls).forEach(field => {
      const control = this.reportForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.reportForm.valid) {
      this.buttonsDisabled = true;

      const subcontractorIds = this.userIsNobinaLeveransansvarig ? this.subcontractorsCtrl.value : (this.userIsOperatorTrafikledare ? [] : [+this.garageIds[0]]);
      const operatorIds = this.selectedOperators();

      const startDate = DateTimePickerHelper.getSelectedDateTime(this.startDateCtrl.value, 0, 0);
      let endDate = DateTimePickerHelper.getSelectedDateTime(this.endDateCtrl.value, 0, 0);
      // include the day of the enddate
      endDate.setDate(endDate.getDate() + 1);
      endDate = new Date(endDate.getTime() - 1);
      this.outcomeService.getReportFile(
        operatorIds,
        subcontractorIds,
        startDate,
        endDate)
        .subscribe(x => {
          this.createFile(x.body, x.headers.get('content-disposition'));
          this.buttonsDisabled = false;
        });
    }
  }

  createExportReport() {
    // trigger validation on all fields
    Object.keys(this.reportForm.controls).forEach(field => {
      const control = this.reportForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.reportForm.valid) {
      this.buttonsDisabled = true;

      const subcontractorIds = this.userIsNobinaLeveransansvarig ? this.subcontractorsCtrl.value : (this.userIsOperatorTrafikledare ? [] : [+this.garageIds[0]]);
      const operatorIds = this.selectedOperators();

      const startDate = DateTimePickerHelper.getSelectedDateTime(this.startDateCtrl.value, 0, 0);
      let endDate = DateTimePickerHelper.getSelectedDateTime(this.endDateCtrl.value, 0, 0);
      // include the day of the enddate
      endDate.setDate(endDate.getDate() + 1);
      endDate = new Date(endDate.getTime() - 1);
      this.outcomeService.getExportOrderReportFile(
        operatorIds,
        subcontractorIds,
        startDate,
        endDate,
        this.includeUnassignedDriveOrders)
        .subscribe(x => {
          this.createFile(x.body, x.headers.get('content-disposition'));
          this.buttonsDisabled = false;
        });
    }
  }

  selectedOperators() {
    return (this.userIsNobinaLeveransansvarig || (this.userIsOperatorTrafikledare && this.userIsOperatorWithMultipleOrganisations))
          ? this.operatorsCtrl.value
          : this.userIsOperatorTrafikledare
            ? [+this.garageIds[0]]
            : []; // TODO Should result in error to User.
  }

  createFile(data: Blob, contentDisposition: string) {

    const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].replace(/"/g, '').replace(/:/g, '.').trim();

    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);

    // window.open(url);

    const element = document.createElement('a');
    element.href = url;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }

}
