import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserRoles } from "@authentication/user-roles";
import { BaseComponent } from "@common/components/base.component";
import { Order } from "@models/order";
import { OrderState } from "@models/order-state";
import { Reason } from "@models/reason";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "@services/auth.service";
import { CancelReasonUtilService } from "@services/cancel-reason-util.service";
import { OrderService } from "@services/order.service";
import { ReasonService } from "@services/reason.service";
import { forkJoin } from "rxjs";
import { CancelOrderComponent } from "../cancel-order/cancel-order.component";
import { CloseOrderComponent } from "../close-order/close-order.component";
import { ConfirmModalComponent } from "@common/components/confirm-modal/confirm-modal.component";
import { DatePipe } from "@angular/common";
import { AlertService } from "@services/alert.service";


@Component({
  selector: 'app-product-order',
  templateUrl: './product-order.component.html',
  styleUrls: ['./product-order.component.scss'],
})
export class ProductOrderComponent extends BaseComponent implements OnInit {
  order: Order;
  orderForm: UntypedFormGroup;
  reasons: Reason[];
  UserRoles = UserRoles;
  submitDisabled: boolean;
  OrderState = OrderState;
  isHistory: boolean;
  buttonsDisabled = false;
  cancellationReason: string;
  modalRef: NgbModalRef;
  timeStamp = new Date();
  currentComment = null;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private reasonService: ReasonService,
    private authService: AuthService,
    private modalService: NgbModal,
    private cancelReasonUtilService: CancelReasonUtilService,
    private datePipe: DatePipe,
    private alertService: AlertService
  ) {
    super();
  }

  // Form controls helpers used in validation
  get reason() {
    return this.orderForm.get('reason');
  }
  get reasonAdditionalInfo() {
    return this.orderForm.get('reasonAdditionalInfo');
  }
  get referenceId() {
    return this.orderForm.get('referenceId');
  }
  get otherRequests() {
    return this.orderForm.get('otherRequests');
  }

  ngOnInit() {
    this.isHistory = this.router.url.startsWith('/history');
    this.createForm();
    this.getOrder();
  }

  createForm() {
    this.orderForm = this.fb.group({
      reason: ['', Validators.required],
      reasonAdditionalInfo: '',
      referenceId: '',
      otherRequests: '',
    });
  }

  getOrder(): void {
    this.order = new Order();
    this.order.id = +this.route.snapshot.paramMap.get('id');


    if (this.order.id !== 0) {
      const order = this.orderService.getOrder(this.order.id);
      const reasons = this.reasonService.getReasons();

      forkJoin([order, reasons]).subscribe(results => {
        this.reasons = results[1];
        this.onOrderRetrieved(results[0].body);
      });
    } else {
      this.reasonService.getReasons().subscribe(reasons => (this.reasons = reasons));
    }
  }

  onOrderRetrieved(order: Order): void {
    this.order = order;
    const selectedReason = this.reasons.find(x => x.id === order.orderReason.id);
    this.cancellationReason = this.cancelReasonUtilService.reasons.get(this.order?.cancellationDetails?.reasonEnum) || null;
    this.orderForm.patchValue({
      referenceId: order.referenceId,
      orderId: order.id,
      otherRequests: order.otherRequests,
      reason: selectedReason,
      reasonAdditionalInfo: order.orderReason.additionalInfo,
    });
    this.updateFormState();

    if (this.order.currentState.stateEnum === OrderState.SentToNobina
      && this.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare])) {
      this.orderService.updateOrderState(this.order.id, OrderState.ReceivedByNobina)
        .subscribe(null);
    }

    if (this.isHistory) {
      this.orderForm.disable();
    }
  }

  updateFormState(){
    if (this.order.currentState.stateEnum === OrderState.Active) {
      this.orderForm.get('reason').disable();
      this.orderForm.get('reasonAdditionalInfo').disable();
      this.orderForm.get('otherRequests').disable();
    }
  }

  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }

  save(): void {
    Object.keys(this.orderForm.controls).forEach(field => {
      const control = this.orderForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.orderForm.valid) {
      const o = Object.assign(this.order, this.orderForm.value);
      o.ReasonId = Number(this.orderForm.controls['reason'].value.id);

      this.orderService.saveOrder(o).subscribe(() => {
        const msg = $localize`:@@order.update-success:Beställning uppdaterad`;
        this.alertService.success(msg);
        setTimeout(() => {
          this.alertService.clear();
        }, 5000);
      });

      this.orderForm.markAsPristine();
      this.orderForm.markAsUntouched();
    }
  }

  activateOrder() {
    this.buttonsDisabled = true;
    const url = `${window.location.origin}/order-new/order/${this.order.id}/assignment`
    this.orderService.updateOrderState(this.order.id, OrderState.Active)
      .subscribe(() => window.location.assign(url));
  }

  dismiss(): void {
    if (this.isHistory) {
      this.router.navigateByUrl('history');
    } else {
      this.router.navigateByUrl('orders');
    }
  }

  cancelOrder() {
    this.buttonsDisabled = true;
    const modalRef = this.modalService.open(CancelOrderComponent);
    modalRef.componentInstance.orderId = this.order.id;
    modalRef.componentInstance.isProductBased = this.order.isProductBased;
    modalRef.result
      .then(() => {
        this.order.currentState.stateEnum = OrderState.Cancelled;
        this.getOrder();
        this.buttonsDisabled = false;
      })
      .catch(() => {
        this.buttonsDisabled = false;
      });
  }

  closeOrder() {
    this.buttonsDisabled = true;
    const modalRef = this.modalService.open(CloseOrderComponent);
    modalRef.componentInstance.orderId = this.order.id;
    modalRef.componentInstance.isProductBased = true;
    modalRef.result
      .then(() => {
        this.getOrder();
        this.buttonsDisabled = false;
      })
      .catch(() => {
        this.buttonsDisabled = false;
      });
  }

  navigateToNewOrderPage(orderId: string) {
    window.location.href = `${window.location.origin}/order-new/order/${orderId}`;
  }

  sendComment(comment: string) {
      this.currentComment = null;
      const timeStamp = this.datePipe.transform(this.timeStamp, 'short');
      this.modalRef = this.modalService.open(ConfirmModalComponent);
      this.modalRef.componentInstance.title = $localize`:Skicka?@@order.comment-title:Skicka?`;
      this.modalRef.componentInstance.description = $localize`:Vill du lägga till denna anteckning? Observera att den inte går att ta bort@@order.comment-description:Vill du lägga till denna anteckning? Observera att den inte går att ta bort`;
      this.modalRef.componentInstance.confirmButton = $localize`:Bekräfta@@order.comment-confirmbtn:Bekräfta`;
      this.modalRef.componentInstance.cancelButton = $localize`:Avbryt@@order.comment-cancelbtn:Avbryt`;

      this.modalRef.result.then(() => {
        this.orderService.sendComment(this.order.id, comment, this.authService.user.idTokenClaims.name, timeStamp)
          .subscribe(resp => {
            this.ngOnInit();
          });
      })
        .catch(reason => {
        });
    }

  parseComment(comment: string): string {
    comment = comment.replace(new RegExp(/<br>/g), '\n');
    return comment;
  }

  onCommentChange(event: Event) {
    this.currentComment = (event.target as HTMLInputElement).value;
  }
}
