import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Order } from '../../common/models/order';
import { BaseComponent } from '../../common/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserRoles } from '../../authentication/user-roles';
import { AuthService } from '../../common/services/auth.service';
import { UnboundOrder } from '../../common/models/unbound-order';
import { ActivatedRoute, Router } from '@angular/router';
import { Operator } from '../../common/models/operator';
import { Reason } from '../../common/models/reason';
import { ReasonService } from '../../common/services/reason.service';
import { OperatorService } from '../../common/services/operator.service';
import { UnboundOrderService } from '../../common/services/unbound-order.service';
import { forkJoin } from 'rxjs';
import { OrderByService } from '../../common/services/order-by.service';
import { UnboundOrderState } from '../../common/models/unbound-order-state';
import { DateTimePickerHelper } from '../../common/datepicker-helpers';
import { HttpErrorResponse } from '@angular/common/http';
import { CancelUnboundOrderComponent } from './cancel-unbound-order/cancel-unbound-order.component';
import { CloseUnboundOrderComponent } from './close-unbound-order/close-unbound-order.component';

@Component({
  selector: 'app-unboundorder',
  templateUrl: './unboundorder.component.html',
  styleUrls: ['./unboundorder.component.scss']
})
export class UnboundorderComponent extends BaseComponent implements OnInit {
  @Input() unboundOrder: UnboundOrder;
  unboundOrderForm: UntypedFormGroup;
  now: Date = new Date();
  datepickerDefault: NgbDateStruct = { year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate() };
  UserRoles = UserRoles;
  hoursInDayOrderstartList = Array.from(Array(24).keys());
  minutesInHourList = Array.from(Array(60).keys());
  orderstartMinDate: NgbDateStruct;
  operators: Operator[];
  reasons: Reason[];
  operatorOrganisationId: number;
  @ViewChild('dpStartDate', {static: true}) dpStartDate: any;
  isHistory: boolean;
  buttonsDisabled = false;
  UnboundOrderState = UnboundOrderState;
  // Flags for frontend
  isOperatorDropdownVisible: boolean;
  isSendOrderButtonVisible = true;
  isActivateButtonVisible: boolean;
  isCancelOrderButtonVisible: boolean;
  isVerifyCancelOrderButtonVisible: boolean;

  constructor(private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private unboundOrderService: UnboundOrderService,
    private reasonService: ReasonService,
    private operatorService: OperatorService,
    private orderByService: OrderByService,
    private modalService: NgbModal,
    private authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.orderstartMinDate = { year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate() };
    this.unboundOrder = new UnboundOrder();
    this.isHistory = this.router.url.startsWith('/history');
    this.setUnboundOrderFlags();
    this.createForm();

    this.unboundOrder = new UnboundOrder();
    this.route.paramMap.subscribe(params => {
      this.unboundOrder.id = +params.get('id');
      this.getOrder();
    });

    if (this.isHistory) {
      this.unboundOrderForm.disable();
    }
  }

  setUnboundOrderFlags() {
    this.isOperatorDropdownVisible = this.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare])
    || (this.userHasAnyRole([UserRoles.OperatorTrafikledare]) && (this.authService.user.idTokenClaims.garageIds as string[]).length > 1);
  }

  setButtonFlags() {
    this.isActivateButtonVisible = this.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare])
      && (this.unboundOrder.currentState && (this.unboundOrder.currentState.stateEnum === UnboundOrderState.SentToNobina)
      || (this.unboundOrder.currentState && (this.unboundOrder.currentState.stateEnum === UnboundOrderState.ReceivedByNobina)));
    this.isSendOrderButtonVisible = this.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.OperatorTrafikledare])
      && !this.unboundOrder.currentState;
    this.isCancelOrderButtonVisible = this.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.OperatorTrafikledare])
      && ((this.unboundOrder.currentState && this.unboundOrder.currentState.stateEnum === UnboundOrderState.SentToNobina)
      || (this.unboundOrder.currentState && this.unboundOrder.currentState.stateEnum === UnboundOrderState.ReceivedByNobina)
      || (this.unboundOrder.currentState && this.unboundOrder.currentState.stateEnum === UnboundOrderState.Active));
    this.isVerifyCancelOrderButtonVisible = this.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare])
      && this.unboundOrder.currentState && this.unboundOrder.currentState.stateEnum === UnboundOrderState.Cancelled;

  }

  createForm() {
    this.unboundOrderForm = this.fb.group({
      operator: [null, Validators.required],
      fromPlace: [null, Validators.required],
      toPlace: [null, Validators.required],
      orderstartDate: this.datepickerDefault,
      startTimeHour: ['', Validators.required],
      startTimeMinute: ['', Validators.required],
      immediateStart: false,
      referenceId: '',
      trainNumber: '',
      orderId: { value: '', disabled: true },
      numberOfPassengers: ['', [Validators.required, Validators.min(1), Validators.max(99999), Validators.pattern('[0-9]+')]],
      reason: [null, Validators.required],
      reasonAdditionalInfo: '',
      otherRequests: ''
    });
    if (this.isOperatorDropdownVisible) {
      this.operatorCtrl.enable();
    } else {
      // tågoperatör
      this.operatorCtrl.disable();
    }

  }

  get operatorCtrl() { return this.unboundOrderForm.get('operator'); }
  get fromPlace() { return this.unboundOrderForm.get('fromPlace'); }
  get toPlace() { return this.unboundOrderForm.get('toPlace'); }
  get numberOfPassengers() { return this.unboundOrderForm.get('numberOfPassengers'); }
  get reason() { return this.unboundOrderForm.get('reason'); }
  get orderstartDate() { return this.unboundOrderForm.get('orderstartDate'); }
  get startTimeHour() { return this.unboundOrderForm.get('startTimeHour'); }
  get startTimeMinute() { return this.unboundOrderForm.get('startTimeMinute'); }
  get immediateStart() { return this.unboundOrderForm.get('immediateStart'); }

  OnImmediateStart() {
    if (this.immediateStart.value) {
      this.orderstartDate.disable();
      this.startTimeHour.disable();
      this.startTimeMinute.disable();
    } else {
      this.orderstartDate.enable();
      this.startTimeHour.enable();
      this.startTimeMinute.enable();
    }
  }

  OnOrderstartdateChange() {
    let localdate: NgbDateStruct;
    localdate = this.orderstartDate.value;
  }

  getOrder(): void {
    const initOrder = () => {
      if (this.unboundOrder.id !== 0) {
        const order = this.unboundOrderService.getOrder(this.unboundOrder.id);
        const reasons = this.reasonService.getReasons();

        forkJoin([order, reasons]).subscribe((results) => {
          this.reasons = this.orderByService.transform(results[1], 'description');
          this.onOrderRetrieved(results[0].body);
        });
      } else {
        this.operators = this.operators.filter((x) => x.isEligibleForOrderCreation);
        this.reasonService
          .getReasons()
          .subscribe(
            (reasons) => (this.reasons = this.orderByService.transform(reasons, 'description'))
          );
      }
    };

    this.operators = [];
    if (this.isOperatorDropdownVisible) {
      this.operatorService.getOperators(true)
        .subscribe({
          next: ops => {
            this.operators = ops.body;
            let selectedOperator = this.operators.find(x => x.operatorOrganisationId === this.authService.user.idTokenClaims.garageIds[0]);
            if (selectedOperator === undefined) {
              selectedOperator = null; // Because default value in select element should be selected
            }
            this.unboundOrderForm.patchValue({
              operator: selectedOperator
            });
            initOrder();
          },
          error: err => console.log('error ' + err)
        });
    } else {
      initOrder();
    }
  }

  onOrderRetrieved(order: UnboundOrder): void {
    this.unboundOrder = order;
    this.setButtonFlags();
    const selectedReason = this.reasons.find(x => x.id === order.orderReason.id);
    const selectedOperator = this.operators.find(x => x.id === order.operator.id);
    this.operators = this.operators.filter(x => x.isEligibleForOrderCreation || x.id === order.operator.id);

    this.unboundOrderForm.enable();

    this.unboundOrderForm.patchValue({
      operator: selectedOperator,
      fromPlace: order.fromPlace,
      toPlace: order.toPlace,
      orderstartDate: {
        year: order.startTime.getFullYear(),
        month: order.startTime.getMonth() + 1,
        day: order.startTime.getDate()
      },
      startTimeHour: order.startTime.getHours(),
      startTimeMinute: order.startTime.getMinutes(),
      referenceId: order.referenceId,
      trainNumber: order.trainNumber,
      orderId: order.id,
      numberOfPassengers: order.numberOfPassengers,
      otherRequests: order.otherRequests,
      reason: selectedReason,
      reasonAdditionalInfo: order.orderReason.additionalInfo,
      immediateStart: order.immediateStart
    });

    this.dpStartDate.navigateTo(this.unboundOrderForm.get('orderstartDate').value);

    this.OnImmediateStart();

    if (this.unboundOrder.currentState.stateEnum === UnboundOrderState.SentToNobina
      && this.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare])) {
      this.unboundOrderService.updateOrderState(this.unboundOrder.id, UnboundOrderState.ReceivedByNobina)
        .subscribe(null);
    }
    if (this.isHistory) {
      this.orderstartDate.disable();
      this.startTimeHour.disable();
      this.startTimeMinute.disable();
    }
    this.unboundOrderForm.disable();
  }

  save() {
    // trigger validation on all fields
    Object.keys(this.unboundOrderForm.controls).forEach(field => {
      const control = this.unboundOrderForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });


    if (this.unboundOrderForm.valid) {
      this.buttonsDisabled = true;
      // Copy the form values over the order object values
      const o = Object.assign(this.unboundOrder, this.unboundOrderForm.value);
      o.ReasonId = Number(this.unboundOrderForm.controls['reason'].value.id);
      if (this.isOperatorDropdownVisible) {
        o.operatorOrganisationId = this.unboundOrderForm.controls['operator'].value.operatorOrganisationId;
      } else {
        // tågoperatör
        o.operatorOrganisationId = this.authService.user.idTokenClaims.garageIds[0];
      }
      if (this.immediateStart.value === true) {
        o.startTime = new Date();
      } else {
        o.startTime = DateTimePickerHelper.getSelectedDateTime(this.orderstartDate.value, this.startTimeHour.value, this.startTimeMinute.value);
      }
      this.unboundOrderService.addOrder(o)
        .subscribe(response => this.onSaveComplete(response.headers.get('location')),
          err => this.onHttpError(err));
    }
  }

  activateOrder() {
    this.buttonsDisabled = true;
    this.unboundOrderService.updateOrderState(this.unboundOrder.id, UnboundOrderState.Active)
      .subscribe(() => this.router.navigateByUrl('unboundorder/' + this.unboundOrder.id + '/assignment'));
  }

  onHttpError(httpError: HttpErrorResponse): void {
    this.buttonsDisabled = false;
  }

  onSaveComplete(location: string): void {
    // Reset the form to clear the flags
    this.unboundOrderForm.reset();
    // Reload order or navigate to order confirmation
    let url: string;
    url = 'unboundorder';
    if (this.unboundOrder.id !== 0) {
      url = 'unboundorder/' + this.unboundOrder.id;
    } else {
      url = 'unbound-order-confirmation/' + location.substr(location.lastIndexOf('/') + 1);
    }
    this.router.navigateByUrl(url);
  }

  cancelOrder() {
    this.buttonsDisabled = true;

    const modalRef = this.modalService.open(CancelUnboundOrderComponent);
    modalRef.componentInstance.orderId = this.unboundOrder.id;
    modalRef.result.then(() => {
      this.unboundOrder.currentState.stateEnum = UnboundOrderState.Cancelled;
      this.buttonsDisabled = false;
      this.setButtonFlags();
    })
      .catch(reason => {
        this.buttonsDisabled = false;
      });
  }

  closeOrder() {
    this.buttonsDisabled = true;
    const modalRef = this.modalService.open(CloseUnboundOrderComponent);
    modalRef.componentInstance.orderId = this.unboundOrder.id;
    modalRef.componentInstance.isHastusPlanned = false;
    modalRef.result.then(() => {
      this.unboundOrder.currentState.stateEnum = UnboundOrderState.CancellationConfirmed;
      this.buttonsDisabled = false;
    })
      .catch(reason => {
        this.buttonsDisabled = false;
      });
  }

  dismiss(): void {
    // TODO: fixa url när history implementerat
    if (this.isHistory) {
      this.router.navigateByUrl('history');
    } else {
      this.router.navigateByUrl('orders');
    }
  }


  // TODO: global funktion? Används lite överallt.
  userHasAnyRole(userRoles: UserRoles[]): boolean {
    return this.authService.userHasAnyRole(userRoles);
  }

}
