import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class OrderImportService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/orders/import/';

  constructor(private http: HttpClient) { }

  import(operatorOrganisationId: number, file: File, timeZoneRegion: number): Observable<HttpResponse<any>> {

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(this.baseUrl + operatorOrganisationId + '?timeZoneRegion=' + timeZoneRegion, formData, { observe: 'response' });
  }

}
