import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../../common/services/order.service';
import { Order, OrderRouteStop, OrderRoute } from '../../../common/models/order';
import { OrderByService } from '../../../common/services/order-by.service';
import { UserRoles } from '../../../authentication/user-roles';
import { AuthService } from '../../../common/services/auth.service';
import { GooglemapComponent } from '../../../googlemap/googlemap.component';
import { Vehicle } from '../../../common/models/vehicle';
import { GeodataService } from '../../../common/services/geodata.service';
import { RouteVariant } from '../../../common/models/route';
import { DriveOrderTripRoute } from '../../../common/models/drive-order';
import { DriveOrderService } from '../../../common/services/drive-order.service';
import { Observable, merge, Subscription } from 'rxjs';

@Component({
  selector: 'app-route-map',
  templateUrl: './route-map.component.html',
  styleUrls: ['./route-map.component.scss'],
})
export class RouteMapComponent implements OnInit, OnDestroy {
  orderId: number;
  order = new Order();
  routeGeoData: any = null;
  vehicleGeoData: any = null;
  vehicles: Vehicle[] = null;
  UserRoles = UserRoles;
  disableStreetView = true;
  interval: any;
  mapForm: UntypedFormGroup;
  autoZoom = true;
  routeVariants: DriveOrderTripRoute[];
  subscriptions: Subscription[] = []; //TODO: Temporary fix to be able to unsubscribe.

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private driveOrderService: DriveOrderService,
    private geodataService: GeodataService,
    private orderByService: OrderByService,
    private authService: AuthService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.mapForm = this.fb.group({
      routeVariant: null,
    });

    this.orderId = +this.route.snapshot.paramMap.get('id');

    if (this.orderId !== 0) {
      this.subscriptions.push(
        this.orderService
          .getOrder(this.orderId)
          .subscribe((result) => this.onOrderRetrieved(result.body))
      );

      this.subscriptions.push(
        this.orderService
          .getActiveVehicles(this.orderId)
          .subscribe((result) => this.onActiveVehiclesRetrieved(result.body))
      );

      this.interval = setInterval(() => {
        this.getVehicleGeoData();
      }, 20000);
    }
  }

  getVehicleGeoData() {
    if (this.vehicles !== null) {
      const vehicleIdentifiers = this.vehicles.map((a) => a.vehicleIdentifier).join(',');
      this.subscriptions.push(
        this.geodataService
          .getVehicleGeoData(vehicleIdentifiers)
          .subscribe((result) => this.onVehicleGeoDataRetrieved(result.body))
      );
    }
  }

  onOrderRetrieved(order: Order) {
    this.order = order;

    this.orderService.getOrderRouteVariants(this.order.id).subscribe((result) => {
      this.routeVariants = result.body;
      if (this.routeVariants.length === 1) {
        this.setSingleRouteVariant();
      }
    });
  }

  getRoute(orderRoute: OrderRoute): DriveOrderTripRoute {
    return {
      routeName: orderRoute.routeName,
      routeVariantId: orderRoute.routeVariantId,
      routeVariantName: orderRoute.routeVariantName,
    };
  }

  onDriveOrderTripRoutesRetrieved(routeVariants: DriveOrderTripRoute[]) {
    this.routeVariants = this.orderByService.transform(routeVariants, [
      'routeName',
      'routeVariantName',
    ]);
    if (this.routeVariants.length === 1) {
      this.setSingleRouteVariant();
    }
  }

  setSingleRouteVariant() {
    this.mapForm.patchValue({
      routeVariant: this.routeVariants[0].routeVariantName,
    });
    this.mapForm.disable();
    this.onRouteVariantChanged(this.routeVariants[0].routeVariantId);
  }

  onRouteGeoDataRetrieved(geoData: any) {
    this.routeGeoData = geoData;
  }
  onVehicleGeoDataRetrieved(geoData: any) {
    this.vehicleGeoData = geoData;
  }
  onActiveVehiclesRetrieved(vehicles: any) {
    if (vehicles !== null) {
      if (vehicles.length > 0) {
        this.vehicles = vehicles;
        this.getVehicleGeoData();
      }
    }
  }

  onRouteVariantChanged(routeVariantId?: number) {
    routeVariantId = routeVariantId ?? this.mapForm.get('routeVariant').value.routeVariantId;

    this.geodataService
      .getRouteGeoData(this.orderId, routeVariantId)
      .subscribe((result) => this.onRouteGeoDataRetrieved(result.body));
  }

  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    clearInterval(this.interval);
  }
}
