export class NotificationMessage {
  id: number;
  title: string;
  main: string;
  submain: string;
  type: string;
  notificationType?: NotificationType;
  data?: { [key: string]: unknown };
  link: string;
  groups: string[];
  isRead: boolean;
  created: Date;

  constructor(
    id: number,
    title: string,
    main: string,
    submain: string,
    type: string,
    link: string,
    groups: string[],
    isRead: boolean,
    created: Date,
    notificationType?: NotificationType,
    data?: { [key: string]: unknown }
  ) {
    this.id = id;
    this.title = title;
    this.main = main;
    this.submain = submain;
    this.type = type;
    this.notificationType = notificationType;
    this.data = data;
    this.link = link;
    this.groups = groups;
    this.isRead = isRead;
    this.created = created;
  }
}

export enum NotificationType {
  DriveOrderInquiryCreated = 1,
  DriveOrderAssigned = 2,
  DriveOrderActivationReminderToSubcontractor = 3,
  PossibleLateFirstDeparture = 4,
  OrderCreated = 5,
  OrderEdited = 6,
  OrderCancelled = 7,
}

export interface DriveOrderInquiryCreated {
  numberOfInquiries: number;
}

export interface DriveOrderAssigned {
  numberOfDriveOrders: number;
}

export interface DriveOrderActivationReminderToSubcontractor {
  subcontractorId: number;
  subcontractorOrderId: number;
  driveOrderId: number;
  driverFullName: string;
  startTime: Date;
}

export interface PossibleLateFirstDeparture {
  orderId: number;
  driveOrderId: number;
  tripId: number;
  startTripStopTime: Date;
}

export interface OrderCreated {
  orderId: number;
  operatorName: string;
  orderType: string;
  isProductBased: boolean;
  routeName: string | null;
  routeStartPlaceDescription: string | null;
  routeEndPlaceDescription: string | null;
}

export interface OrderEdited {
  orderId: number;
  operatorName: string;
  orderType: string;
  isProductBased: boolean;
  routeName: string | null;
  routeStartPlaceDescription: string | null;
  routeEndPlaceDescription: string | null;
}

export interface OrderCancelled {
  orderId: number;
  operatorName: string;
  orderType: string;
  isProductBased: boolean;
  routeName: string | null;
  routeStartPlaceDescription: string | null;
  routeEndPlaceDescription: string | null;
}

