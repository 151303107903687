import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler, TRANSLATIONS, TRANSLATIONS_FORMAT, LOCALE_ID } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { OrderCardListComponent } from '@orders/order-card-list/order-card-list.component';
import { OrderListComponent } from '@orders/order-list/order-list.component';
import { OrderComponent } from '@orders/order/order.component';
import { OrderService } from '@services/order.service';
import { OrderListService } from '@services/orderList.service';
import { OrderImportService } from '@services/order-import.service';
import { RouteService } from '@services/route.service';
import { OperatorService } from '@services/operator.service';
import { JsonInterceptor } from '@services/json.interceptor';
import { AdaptRouteComponent } from '@orders/order/adapt-route/adapt-route.component';
import { OrderConfirmationComponent } from '@orders/order/order-confirmation/order-confirmation.component';
import { ReasonService } from '@services/reason.service';
import { SubcontractorsComponent } from '@orders/order/subcontractors/subcontractors.component';
import { AuthGuardService } from '@services/auth.guard.service';
import { AuthService } from '@services/auth.service';
import { SubcontractorService } from '@services/subcontractor.service';
import { SubcontractorsAvailabilityInquiryComponent } from '@orders/order/subcontractors/subcontractors-availability-inquiry.component';
import { SubcontractorOrderComponent as OrderSubcontractorOrderComponent } from '@orders/order/subcontractors/subcontractor-order.component';
import { FilterCanAssignPipe } from '@orders/order/subcontractors/availability-inquiry-filter';
import { AvailabilityInquiryComponent } from '@orders/subcontractor/availability-inquiry/availability-inquiry.component';
import { AvailabilityInquiryListComponent } from '@orders/subcontractor/availability-inquiry-list/availability-inquiry-list.component';
import { SubcontractorOrderListComponent } from '@orders/subcontractor/subcontractor-order-list/subcontractor-order-list.component';
import { AuthGuardRolesService } from '@services/auth-guard-roles.service';
import { AssignDriverComponent } from '@orders/subcontractor/assign-driver/assign-driver.component';
import { AssignVehicleComponent } from '@orders/subcontractor/assign-vehicle/assign-vehicle.component';
import { AssignSubcontractorComponent } from '@orders/subcontractor/assign-subcontractor/assign-subcontractor.component';
import { SearchPipe } from './search.pipe';
import { OrderByService } from '@services/order-by.service';
import { CancelOrderComponent } from '@orders/order/cancel-order/cancel-order.component';
import { CloseOrderComponent } from '@orders/order/close-order/close-order.component';
import { GooglemapComponent } from './googlemap/googlemap.component';
import { ModalDatetimeComponent } from '@common/components/modal-datetime/modal-datetime.component';
import { ApplicationInsightsService } from '@services/application-insights.service';
import { GlobalErrorHandler } from '@common/global-error-handling';
import { AlertService } from '@services/alert.service';
import { AlertComponent } from '@common/components/alert/alert.component';
import { HastusOrderComponent } from '@orders/order/hastus-order/hastus-order.component';
import { RouteMapComponent } from '@orders/order/route-map/route-map.component';
import { NotificationService } from '@services/notification.service';
import { ToastComponent } from '@common/components/toast/toast.component';
import { OrderMenuComponent } from '@orders/order/order-menu/order-menu.component';
import { SubcontractorOrderService } from '@services/subcontractor-order.service';
import { DriveOrderService } from '@services/drive-order.service';
import { SubcontractorOrderComponent } from '@orders/subcontractor/subcontractor-order/subcontractor-order.component';
import { OrderLogComponent } from '@orders/order/order-log/order-log.component';
import { LogService } from '@services/log.service';
import { AdminService } from '@services/admin.service';
import { AvailabilityInquiryService } from '@services/availability-inquiry.service';
import { SubcontractorRouteMapComponent } from '@orders/subcontractor/subcontractor-order/subcontractor-route-map/subcontractor-route-map.component';
import { BaseComponent } from '@common/components/base.component';
import { ManageOperatorsComponent } from './admin/manage-operators/manage-operators.component';
import { OutcomeListComponent } from './billing/outcome-list/outcome-list.component';
import { OutcomeComponent } from './billing/outcome/outcome.component';
import { OutcomeService } from '@services/outcome.service';
import { OutcomeDetailsComponent } from './billing/outcome/outcome-details/outcome-details.component';
import { OutcomeTripComponent } from './billing/outcome/outcome-details/outcome-trip.component';
import { GeodataService } from '@services/geodata.service';
import { MissingVehicleStopEventsService } from '@services/admin/missingvehiclestopevents.service';
import { UnboundorderComponent } from '@orders/unboundorder/unboundorder.component';
import { UnboundOrderService } from '@services/unbound-order.service';
import { UnboundOrderConfirmationComponent } from '@orders/unboundorder/unbound-order-confirmation/unbound-order-confirmation.component';
import { UnboundOrderListComponent } from '@orders/unbound-order-list/unbound-order-list.component';
import { CheckStatePipe } from './billing/outcome/outcome-checkstate.pipe';
import { UnboundOrderAssignmentComponent } from '@orders/unboundorder/unbound-order-assignment/unbound-order-assignment.component';
import { UnboundOrderCompletionComponent } from '@orders/unboundorder/unbound-order-completion/unbound-order-completion.component';
import { CancelUnboundOrderComponent } from '@orders/unboundorder/cancel-unbound-order/cancel-unbound-order.component';
import { CloseUnboundOrderComponent } from '@orders/unboundorder/close-unbound-order/close-unbound-order.component';
import { ApproveDriveOrderComponent } from './billing/outcome/outcome-details/approve-driveorder/approve-driveorder.component';
import { RejectDriveOrderComponent } from './billing/outcome/outcome-details/reject-driveorder/reject-driveorder.component';
import { OutcomeApprovalContractorComponent } from './billing/outcome/outcome-approval-contractor/outcome-approval-contractor.component';
import { OutcomeApprovalComponent } from './billing/outcome/outcome-approval/outcome-approval.component';
import { UnboundOrderLogComponent } from '@orders/unboundorder/unbound-order-log/unbound-order-log.component';
import { VehicleTypeService } from '@services/vehicle-type.service';
import { VersionComponent } from './admin/version/version.component';
import { CreateReportComponent } from './billing/reports/create-report/create-report.component';
import { OutcomeBilledComponent } from './billing/outcome/outcome-billed/outcome-billed.component';
import { OutcomeBilledContractorComponent } from './billing/outcome/outcome-billed-contractor/outcome-billed-contractor.component';
import { DecimalPipe, registerLocaleData, DatePipe } from '@angular/common';
import { DateFilterComponent } from '@common/components/filters/date-filter.component';
import { SearchComponent } from '@common/components/search/search.component';
import { ImportOrderComponent } from './admin/import-order/import-order.component';
import { AdminMenuComponent } from './admin/admin-menu/admin-menu.component';
import { ConnectRoutevariantComponent } from './admin/connect-routevariant/connect-routevariant.component';
import { ConfirmModalComponent } from '@common/components/confirm-modal/confirm-modal.component';
import { ImportTimetableComponent } from './admin/import-timetable/import-timetable.component';
import { TimetableImportService } from '@services/timetable-import.service';
import { NotificationsComponent } from '@common/components/notifications/notifications.component';
import { NotificationsModalComponent } from '@common/components/notifications-modal/notifications-modal.component';
import { AssignVehicleWarningComponent } from '@orders/subcontractor/assign-vehicle/assign-vehicle-warning/assign-vehicle-warning.component';
import { AssignDriverWarningComponent } from '@orders/subcontractor/assign-driver/assign-driver-warning/assign-driver-warning.component';
import { GeoLocationService } from '@services/geo-location.service';
import { AlertModalComponent } from '@common/components/alert-modal/alert-modal.component';
import { PositionHistoryMapComponent } from '@orders/order/position-history-map/position-history-map.component';
import { environment } from '../environments/environment';
import { DatePickerService } from '@services/date-picker.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { ListViewService } from '@services/list-view.service';
import { OrderListTableComponent } from '@orders/order-list/order-list-table.component';
import { InvalidControlScrollDirective } from '@common/invalid-scroll.directive';
import { MissingVehicleStopEventsComponent } from './admin/missing-vehicle-stop-events/missing-vehicle-stop-events.component';
import { DriveorderDetailsComponent } from './billing/outcome/outcome-details/driveorder-details/driveorder-details.component';
import { OutcomeReferenceComponent } from './billing/outcome/outcome-details/outcome-reference/outcome-reference.component';
import localesv from '@angular/common/locales/sv';
import { AvailabilityInquiryListBaseComponent } from '@orders/subcontractor/availability-inquiry-list-base/availability-inquiry-list-base.component';
import { SubcontractorComponent } from '@orders/subcontractor/subcontractor/subcontractor.component';
import { SubcontractorHistoryComponent } from '@orders/subcontractor/subcontractor-history/subcontractor-history.component';
import { AvailabilityInquiryAcceptedComponent } from '@orders/subcontractor/availability-inquiry/availability-inquiry-accepted/availability-inquiry-accepted.component';
import { AvailabilityInquiryStoreService } from '@services/availability-inquiry-store.service';
import { ManageSubcontractorsComponent } from './admin/manage-subcontractors/manage-subcontractors.component';
import { TableSubcontractorsComponent } from './admin/manage-subcontractors/table-subcontractors/table-subcontractors.component';
import { FilterSubcontractorsComponent } from './admin/manage-subcontractors/filter-subcontractors/filter-subcontractors.component';
import { ImportSubcontractorComponent } from './admin/manage-subcontractors/import-subcontractor/import-subcontractor.component';
import { SidePanelComponent } from './admin/manage-subcontractors/side-panel/side-panel.component';
import { EditSubcontractorComponent } from './admin/manage-subcontractors/edit-subcontractor/edit-subcontractor.component';
import { NumberOfVehiclesComponent } from '@common/components/number-of-vehicles/number-of-vehicles.component';
import { EstimatedArrivalMinutesComponent } from '@common/components/estimated-arrival-minutes/estimated-arrival-minutes.component';
import { AvailabilityComponent } from '@common/components/availabilities/availability/availability.component';
import { AvailabilitiesComponent } from '@common/components/availabilities/availabilities.component';
import { AvailabilitiesFormComponent } from '@common/components/availabilities-form/availabilities-form.component';
import { BackButton } from '@common/components/back-button/back-button';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { LoginFailedComponent } from './login-failed/login-failed.component';
import { msalGuardConfigFactory, msalInstanceFactory, msalInterceptorConfigFactory } from './b2c-config-functions';
import { HttpCustomInterceptor } from '@common/http-custom-interceptor';
import { CancelReasonUtilService } from '@services/cancel-reason-util.service';
import { ClearComponent } from './clear/clear.component';
import { NavBarComponent } from '@common/components/nav-bar/nav-bar.component';
import { ServerNotificationService } from '@services/server-notification.service';
import { ProductOrderComponent } from '@orders/order/new-order/product-order.component';

declare const require;

registerLocaleData(localesv);

export function localeFactory(): string {
  return environment.language;
}

@NgModule({
  declarations: [
    NavBarComponent,
    ClearComponent,
    BaseComponent,
    AppComponent,
    OrderCardListComponent,
    OrderListComponent,
    OrderListTableComponent,
    OrderComponent,
    AdaptRouteComponent,
    OrderConfirmationComponent,
    SubcontractorsComponent,
    OrderSubcontractorOrderComponent,
    SubcontractorsAvailabilityInquiryComponent,
    SubcontractorOrderComponent,
    FilterCanAssignPipe,
    AvailabilityInquiryComponent,
    AvailabilityInquiryListComponent,
    SubcontractorOrderListComponent,
    AssignDriverComponent,
    AssignVehicleComponent,
    AssignSubcontractorComponent,
    SearchPipe,
    CancelOrderComponent,
    CloseOrderComponent,
    GooglemapComponent,
    ModalDatetimeComponent,
    AlertComponent,
    HastusOrderComponent,
    ProductOrderComponent,
    ToastComponent,
    OrderMenuComponent,
    OrderLogComponent,
    SubcontractorRouteMapComponent,
    ManageOperatorsComponent,
    OutcomeListComponent,
    OutcomeComponent,
    OutcomeDetailsComponent,
    OutcomeTripComponent,
    OutcomeApprovalComponent,
    OutcomeApprovalContractorComponent,
    UnboundorderComponent,
    ManageSubcontractorsComponent,
    UnboundOrderConfirmationComponent,
    UnboundOrderListComponent,
    CheckStatePipe,
    UnboundOrderAssignmentComponent,
    UnboundOrderCompletionComponent,
    CancelUnboundOrderComponent,
    CloseUnboundOrderComponent,
    ApproveDriveOrderComponent,
    RejectDriveOrderComponent,
    UnboundOrderLogComponent,
    VersionComponent,
    CreateReportComponent,
    OutcomeBilledComponent,
    OutcomeBilledContractorComponent,
    DateFilterComponent,
    SearchComponent,
    ImportOrderComponent,
    ImportTimetableComponent,
    AdminMenuComponent,
    ConnectRoutevariantComponent,
    ConfirmModalComponent,
    AlertModalComponent,
    NotificationsComponent,
    NotificationsModalComponent,
    AssignVehicleWarningComponent,
    AssignDriverWarningComponent,
    PositionHistoryMapComponent,
    InvalidControlScrollDirective,
    RouteMapComponent,
    MissingVehicleStopEventsComponent,
    DriveorderDetailsComponent,
    OutcomeReferenceComponent,
    AvailabilityInquiryListBaseComponent,
    SubcontractorComponent,
    SubcontractorHistoryComponent,
    AvailabilityInquiryAcceptedComponent,
    ManageSubcontractorsComponent,
    TableSubcontractorsComponent,
    FilterSubcontractorsComponent,
    ImportSubcontractorComponent,
    SidePanelComponent,
    EditSubcontractorComponent,
    NumberOfVehiclesComponent,
    EstimatedArrivalMinutesComponent,
    AvailabilityComponent,
    AvailabilitiesComponent,
    AvailabilitiesFormComponent,
    BackButton,
    LoginFailedComponent,
  ],
  imports: [
    MsalModule,
    BrowserModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbModalModule,
    AppRoutingModule,
    MomentModule,
    NgxPaginationModule,
    NgProgressModule.withConfig({
      direction: 'ltr+',
      trickleSpeed: 300,
      debounceTime: 500,
      speed: 200,
      min: 8,
      max: 100,
      ease: 'linear',
      spinner: false,
      spinnerPosition: 'left',
      color: '#00693c',
      thick: false,
      meteor: true,
    }),
    NgProgressHttpModule,
    BrowserAnimationsModule, // required for ToastrModule
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      timeOut: 0,
      extendedTimeOut: 1200,
      easeTime: 100,
      tapToDismiss: false,
      closeButton: true,
      enableHtml: true,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    OrderService,
    OrderListService,
    OrderImportService,
    TimetableImportService,
    AvailabilityInquiryService,
    AvailabilityInquiryStoreService,
    DriveOrderService,
    SubcontractorService,
    SubcontractorOrderService,
    RouteService,
    ReasonService,
    VehicleTypeService,
    OperatorService,
    AuthGuardService,
    AuthGuardRolesService,
    AuthService,
    OrderByService,
    AlertService,
    ApplicationInsightsService,
    NotificationService,
    LogService,
    AdminService,
    OutcomeService,
    GeodataService,
    MissingVehicleStopEventsService,
    GeoLocationService,
    UnboundOrderService,
    CancelReasonUtilService,
    DatePickerService,
    DecimalPipe,
    ListViewService,
    ServerNotificationService,
    DatePipe,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: TRANSLATIONS,
      useFactory: (locale: string) => {
        locale = locale || 'sv'; // default to sv if no locale provided
        if (locale === 'sv' || locale === 'sv-SE') {
          return '';
        }
        return require(`!!raw-loader!../resources/locale/messages.${locale}.xlf`);
      },
      deps: [LOCALE_ID],
    },
    {
      provide: LOCALE_ID,
      useFactory: localeFactory,
    },
    {
      provide: TRANSLATIONS_FORMAT,
      useValue: 'xlf',
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCustomInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
}
