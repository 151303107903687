<h1 class="my-5 text-center"><ng-container i18n="@@subcontractor-order.order">Beställning</ng-container> {{ subcontractorOrder?.orderId  }}</h1>


<ul class="nav nav-tabs mx-auto">
  <li class="nav-item">
    <a class="nav-link active" [routerLink]="'/subcontractor/' + subcontractorId + (isHistory ? '/history/' : '/subcontractor-orders/') + subcontractorOrder?.id" i18n="@@subcontractor-order.order">Beställning</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" *ngIf="(!!subcontractorOrder && subcontractorOrder.numberOfVehiclesAssigned > 0)" (click)="navigateToDriveOrder()" i18n="@@subcontractor-order.driveorders">Körordrar</a>
    <a class="nav-link disabled" *ngIf="(!!subcontractorOrder && subcontractorOrder.numberOfVehiclesAssigned <= 0)" i18n="@@subcontractor-order.driveorders">Körordrar</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="'/subcontractor/' + subcontractorId + (isHistory ? '/history/' : '/subcontractor-orders/') + subcontractorOrder?.id + '/subcontractor-route-map'" i18n="@@subcontractor-order.route-map">Linjesträckningskarta</a>
  </li>
</ul>

<div class="container row justify-content-center">
  <div class="col ">
    <div class="callout-block-lg">
      <span class="callout"></span>
      <div class="caption-lt" i18n="@@subcontractor-order.number-of-vehicles-assigned">Antal fordon tilldelade</div>
      <div class="row">
        <div class="col">
          <div class="blackish">{{ getAssignedVehicles() }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="!subcontractorOrder.orderIsProductBased" class="callout-block-lg">
      <span class="callout"></span>
      <div class="caption-lt">
        <ng-container *ngIf="subcontractorOrder?.isRoundTrip" i18n="@@subcontractor-order.round-trip">Tur och retur</ng-container>
        <ng-container *ngIf="!subcontractorOrder?.isRoundTrip" i18n="@@subcontractor-order.one-way">Enkel</ng-container>
      </div>
      <div class="row">
        <div class="col">
          <div class="blackish" id="startTripStop">{{subcontractorOrder?.startOrderRouteStopDescription}}</div>
        </div>
        <div class="col-1">
          <img class="right-arrow" src="/assets/img/icons/right-arrow.png" alt="Icon right-arrow">
        </div>
        <div class="col">
          <div class="blackish" id="endTripStop">{{subcontractorOrder?.endOrderRouteStopDescription}}</div>
        </div>
      </div>
    </div>
    <div class="callout-block-lg">
      <span class="callout"></span>
      <div class="caption-lt" i18n="@@subcontractor-order.period">Period</div>
      <div class="row">
        <div class="col">
          <div class="blackish" id="startTime">{{ subcontractorOrder?.startTripStop?.passTime | amCalendar }}</div>
        </div>
        <div class="col-1" >
          <img class="right-arrow" src="/assets/img/icons/right-arrow.png" alt="Icon right-arrow">
        </div>
        <div class="col">
          <div class="blackish" id="endTime">{{ subcontractorOrder?.endTripStop?.passTime | amCalendar }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="subcontractorOrder?.orderTrainNumber" class="callout-block-lg">
      <span class="callout"></span>
      <div class="caption-lt" i18n="@@subcontractor-order.train-number">Tågnummer</div>
      <div class="row">
        <div class="col">
          <div class="blackish" id="orderTrainNumber">{{subcontractorOrder?.orderTrainNumber}}</div>
        </div>
      </div>
    </div>
    <div class="callout-block-lg">
      <span class="callout"></span>
      <div class="caption-lt" i18n="@@subcontractor-order.reason">Orsak</div>
      <div class="row">
        <div class="col">
          <div class="blackish">{{subcontractorOrder?.orderReason?.description}}</div>
        </div>
      </div>
    </div>
    <div class="callout-block-lg" *ngIf="subcontractorOrder?.orderReason?.additionalInfo">
      <span class="callout"></span>
      <div class="caption-lt" i18n="@@subcontractor-order.reason.additionalInfo">Ytterligare info</div>
      <div class="row">
        <div class="col">
          <div class="blackish">{{subcontractorOrder?.orderReason?.additionalInfo}}</div>
        </div>
      </div>
    </div>
  </div>

</div>
