import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Garages } from '@models/garages';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GarageService {

  private garageBaseUrl = environment.garageUrl + environment.apiVersionGarage;

  constructor(private http: HttpClient) {
  }

  getGarageState(): Observable<Garages> {
    const url = `${this.garageBaseUrl}/subcontractors`;
    return this.http.get<Garages>(url);
  }

  syncSubcontractor(garageCode: string) {
    const url = `${this.garageBaseUrl}/subcontractors/sync/${garageCode}`;
    return this.http.post(url, null);
  }
}
