import { Injectable } from '@angular/core';
import {
  DriveOrderAssigned,
  DriveOrderInquiryCreated,
  NotificationMessage,
  NotificationType,
  OrderCancelled,
  OrderCreated,
  OrderEdited,
  PossibleLateFirstDeparture,
  DriveOrderActivationReminderToSubcontractor
} from '@models/notification-message';
import moment from 'moment';

@Injectable()
export class ServerNotificationService {
  setServerNotificationLocalization(notification: NotificationMessage): NotificationMessage {
    switch (notification.notificationType) {
      case NotificationType.DriveOrderInquiryCreated:
        this.setDriveOrderInquiryCreatedNotification(notification);
        break;
      case NotificationType.DriveOrderAssigned:
        this.setDriveOrderAssignedNotification(notification);
        break;
      case NotificationType.PossibleLateFirstDeparture:
        this.setPossibleLateFirstDepartureNotification(notification);
        break;
      case NotificationType.OrderCreated:
        this.setOrderCreatedNotification(notification);
        break;
      case NotificationType.OrderEdited:
        this.setOrderEditedNotification(notification);
        break;
      case NotificationType.OrderCancelled:
        this.setOrderCancelledNotification(notification);
        break;
      case NotificationType.DriveOrderActivationReminderToSubcontractor:
        this.setDriveOrderActivationReminderToSubcontractorNotification(notification);
        break;

      default:
        break;
    }
    return notification;
  }

  private setDriveOrderAssignedNotification(notification: NotificationMessage) {
    const driveOrderAssignedData = notification.data as unknown as DriveOrderAssigned;
    const numberOfDriveOrders = driveOrderAssignedData.numberOfDriveOrders;
    notification.title = $localize`:@@drive-order.assigned-notification.title:Ny tilldelning`;

    if (numberOfDriveOrders > 1) {
      notification.main = this.translationStringSubstitution(
        $localize`:@@drive-orders.assigned-notification.message:Du har blivit tilldelad ${numberOfDriveOrders} nya körordrar`,
        numberOfDriveOrders.toString()
      );
    } else {
      notification.main = this.translationStringSubstitution(
        $localize`:@@drive-order.assigned-notification.message:Du har blivit tilldelad ${numberOfDriveOrders} ny körorder`,
        numberOfDriveOrders.toString()
      );
    }
    notification.link = 'orders';
  }

  private setDriveOrderInquiryCreatedNotification(notification: NotificationMessage) {
    const driveOrderInquiryCreatedData = notification.data as unknown as DriveOrderInquiryCreated;
    const numberOfInquiries = driveOrderInquiryCreatedData.numberOfInquiries;
    notification.title = $localize`:@@drive-order.inquiry-notification.title:Ny inkommen förfrågan`;

    if (numberOfInquiries > 1) {
      notification.main = this.translationStringSubstitution(
        $localize`:@@drive-order.inquiries-notification.message:Du har ${numberOfInquiries} nya förfrågningar`,
        numberOfInquiries.toString()
      );
    } else {
      notification.main = this.translationStringSubstitution(
        $localize`:@@drive-order.inquiry-notification.message:Du har ${numberOfInquiries} ny förfrågan`,
        numberOfInquiries.toString()
      );
    }
    notification.link = `${window.location.origin}/order-new/planning`;
  }

  private setPossibleLateFirstDepartureNotification(notification: NotificationMessage) {
    const data = notification.data as unknown as PossibleLateFirstDeparture;
    const { orderId, driveOrderId, tripId, startTripStopTime } = data;
    const time = moment(startTripStopTime).format('HH:mm');

    notification.title = this.translationStringSubstitution(
      $localize`:@@drive-order.possible-late-first-departure-notification.title:Framkörning ej inledd för körorder ${driveOrderId}`,
      driveOrderId.toString()
    );
    notification.main = this.translationStringSubstitution(
      $localize`:@@drive-order.possible-late-first-departure-notification.message:Körorder ${driveOrderId} med tur ${tripId}, starttid klockan ${time} har ej inlett framkörning.`,
      driveOrderId.toString(),
      tripId.toString(),
      time
    );
    notification.link = `${window.location.origin}/order-new/order/${orderId}/drive-order#${driveOrderId}`;
  }

  private setOrderCreatedNotification(notification: NotificationMessage) {
    const data = notification.data as unknown as OrderCreated;
    const {
      orderId,
      operatorName,
      orderType,
      isProductBased,
      routeName,
      routeStartPlaceDescription,
      routeEndPlaceDescription,
    } = data;

    notification.title = this.translationStringSubstitution(
      $localize`:@@order.order-created-notification.title:Ny beställning, ${orderId}`,
      orderId.toString()
    );
    notification.main = isProductBased
      ? this.translationStringSubstitution(
          $localize`:@@order.order-created-notification.product-based.message:TODO`
        )
      : this.translationStringSubstitution(
          $localize`:@@order.order-created-notification.message:${routeName} ${routeStartPlaceDescription} - ${routeEndPlaceDescription}`,
          routeName,
          routeStartPlaceDescription,
          routeEndPlaceDescription
        );
    notification.submain = this.translationStringSubstitution(
      $localize`:@@order.order-created-notification.sub-message:${operatorName}`
    );
    notification.type = orderType;
    notification.link = isProductBased ? `/order/product-order/${orderId}` : `/order/${orderId}`;
  }

  private setOrderEditedNotification(notification: NotificationMessage) {
    const data = notification.data as unknown as OrderEdited;
    const {
      orderId,
      operatorName,
      orderType,
      isProductBased,
      routeName,
      routeStartPlaceDescription,
      routeEndPlaceDescription,
    } = data;

    notification.title = this.translationStringSubstitution(
      $localize`:@@order.order-edited-notification.title:Ändrad beställning, ${orderId}`,
      orderId.toString()
    );
    notification.main = isProductBased
      ? this.translationStringSubstitution(
          $localize`:@@order.order-edited-notification.product-based.message:TODO`
        )
      : this.translationStringSubstitution(
          $localize`:@@order.order-edited-notification.message:${routeName} ${routeStartPlaceDescription} - ${routeEndPlaceDescription}`,
          routeName,
          routeStartPlaceDescription,
          routeEndPlaceDescription
        );
    notification.submain = this.translationStringSubstitution(
      $localize`:@@order.order-edited-notification.sub-message:${operatorName}`
    );
    notification.type = orderType;
    notification.link = isProductBased ? `/order/product-order/${orderId}` : `/order/${orderId}`;
  }

  private setOrderCancelledNotification(notification: NotificationMessage) {
    const data = notification.data as unknown as OrderCancelled;
    const {
      orderId,
      operatorName,
      isProductBased,
      routeName,
      routeStartPlaceDescription,
      routeEndPlaceDescription,
    } = data;

    notification.title = this.translationStringSubstitution(
      $localize`:@@order.order-cancelled-notification.title:Avbeställning, ${orderId}`,
      orderId.toString()
    );
    notification.main = isProductBased
      ? this.translationStringSubstitution(
          $localize`:@@order.order-cancelled-notification.product-based.message:TODO`
        )
      : this.translationStringSubstitution(
          $localize`:@@order.order-cancelled-notification.message:${routeName} ${routeStartPlaceDescription} - ${routeEndPlaceDescription}`,
          routeName,
          routeStartPlaceDescription,
          routeEndPlaceDescription
        );
    notification.submain = this.translationStringSubstitution(
      $localize`:@@order.order-cancelled-notification.sub-message:${operatorName}`
    );
    notification.link = isProductBased ? `/order/product-order/${orderId}` : `/order/${orderId}`;
  }

  private setDriveOrderActivationReminderToSubcontractorNotification(notification: NotificationMessage) {
    const data = notification.data as unknown as DriveOrderActivationReminderToSubcontractor;
    const {
      subcontractorId,
      subcontractorOrderId,
      driveOrderId,
      driverFullName,
      startTime
    } = data;

    const time = moment(startTime).format('HH:mm');
    notification.title = this.translationStringSubstitution(
      $localize`:@@order.order-activated-notification.title:Körorderaktivering påminnelse, ${driveOrderId}`,
      driveOrderId.toString()
    );
    notification.main = this.translationStringSubstitution(
          $localize`:@@order.order-activated-notification.message:Förare ${driverFullName} har ej aktiverat körorder ${driveOrderId} med starttid ${time}`,
          driverFullName,
          driveOrderId.toString(),
          time
        );
        notification.link = `${window.location.origin}/order-new/subcontractor/${subcontractorId}/subcontractor-orders/${subcontractorOrderId}/drive-order#${driveOrderId}`;
  }

  private translationStringSubstitution(translation: string, ...args: string[]): string {
    args.forEach((arg, index) => {
      translation = translation.replace(`{${index}}`, arg);
    });
    return translation;
  }
}
