import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Order, OrderForMenu, OrderRouteStop, OrderActionState, OrderView } from '../../common/models/order';
import { OrderType } from '../../common/models/order-type';
import { OrderService } from '../../common/services/order.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderByService } from '../../common/services/order-by.service';
import { UserRoles } from '../../authentication/user-roles';
import { AuthService } from '../../common/services/auth.service';
import { OrderState } from '../../common/models/order-state';
import { SessionStorage } from '../../common/sessionStorage.decorator';
import { OrderSearch } from '../../common/models/order-search';
import { NotificationService } from '../../common/services/notification.service';
import { DatePickerService } from '../../common/services/date-picker.service';
import { DateRange } from '../../common/models/dateRange';
import { roundOffMinutes } from '@common/date-extension';
import { DateFilterOption } from '@services/DateFilterOption';
import { DateOrigin } from '@models/date-origin';

@Component({
  selector: 'app-order-card-list',
  templateUrl: './order-card-list.component.html',
  styleUrls: ['./order-card-list.component.scss']
})
export class OrderCardListComponent implements OnInit, OnDestroy {
  dateOrigin = DateOrigin.History;

  @SessionStorage
  ordersForMenu: string;
  dateFilterOptionEnum = DateFilterOption;
  status: string;
  dateRangeFilter: DateRange;
  paramsub: Subscription;
  orders: Order[];
  filterForm: UntypedFormGroup;
  ordertype: number;
  isHistory: boolean;

  OrderState = OrderState;
  OrderType = OrderType;
  UserRoles = UserRoles;
  OrderActionState = OrderActionState;
  stateChangedMessageSub: Subscription;

  constructor(private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private orderByService: OrderByService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private datePickerService: DatePickerService) { }

  ngOnInit() {
    this.dateRangeFilter = this.datePickerService.historyDateRange;
    this.isHistory = this.router.url.startsWith('/history');
    const defaultOrderType = this.isHistory ? OrderType.Unplanned : OrderType.AnyType;
    this.paramsub = this.route
      .queryParams
      .subscribe(params => {
        this.ordertype = +params['ordertype'] || defaultOrderType;
        this.getOrders(this.isHistory);
      });
    this.createFilterForm();
    this.stateChangedMessageSub = this.notificationService.getStateChangedMessage().subscribe(message => {
      if (message.referenceType === 'Order' && (message.isCreated || this.orders.find(o => o.id === message.referenceId))) {
        this.getOrders(this.isHistory);
      }
    });
  }

  getOrderTypes(ordertype: OrderType): OrderType[] {
    if (ordertype === OrderType.AnyType) {
      return [OrderType.Urgent, OrderType.Unplanned, OrderType.Planned];
    }

    if (ordertype === OrderType.Unplanned) {
      return [OrderType.Urgent, OrderType.Unplanned];
    }

    return [ordertype];
  }

  // TODO:Skapa tester för att verifiera att rätt states filtreras. Går det då filtreringen görs av api:et.
  getOrderStates(ordertype: OrderType): OrderState[] {
    if (ordertype === OrderType.AnyType) {
      return [OrderState.Ongoing, OrderState.Cancelled, OrderState.Completing, OrderState.ReportedComplete];
    }

    if (this.isHistory) {
      return [OrderState.Completed];
    }

    return [OrderState.SentToNobina, OrderState.ReceivedByNobina, OrderState.Active];
  }

  isUnboundOrder(): boolean {
    return false;
  }

  public onDateRangeChanged(dateRange: DateRange): void {
    if (dateRange === undefined && (this.datePickerService.historyFromDate === null && this.datePickerService.historyToDate === null)) {
      this.dateRangeFilter = undefined;
    } else {
      this.datePickerService.historyDateRange = dateRange;
      this.dateRangeFilter = dateRange;
    }
    this.getOrders(this.isHistory);
  }

  createFilterForm() {
    this.filterForm = this.fb.group({
      routeFilter: '',
      statusFilter: null
    });
  }

  getOrders(orderByDesc: boolean) {
    const orderSearch = new OrderSearch();
    orderSearch.orderTypes = this.getOrderTypes(this.ordertype);
    orderSearch.orderStates = this.getOrderStates(this.ordertype);
    orderSearch.dateRange = (this.isHistory ? this.dateRangeFilter : undefined);
    
    this.orderService.getOrders(orderSearch)
      .subscribe(
        orders => {
          this.orders = orders.body.map(order => {
            const activeRouteStops = order.orderRoute?.orderRouteStops?.filter(stop => stop.isActive) || [];
            return {
              ...order,
              activeRouteStops,
              startPlace: activeRouteStops.length ? activeRouteStops[0].placeDescription : '',
              endPlace: activeRouteStops.length ? activeRouteStops[activeRouteStops.length - 1].placeDescription : ''
            } as OrderView;
          });

          if (this.orders.length > 0) {
            const ordersForMenu = this.orders.map(x => new OrderForMenu(x.id, x.isHastusPlanned, x.orderRoute?.routeName ?? ''));
            this.ordersForMenu = JSON.stringify(ordersForMenu);
          }
          roundOffMinutes([
            ...this.orders.flatMap(o => o.startTripStop?.passTime),
            ...this.orders.flatMap(o => o.endTripStop?.passTime)
          ]);
          this.orders = this.orderByService.transform(this.orders, 'startTime', orderByDesc);
        });
  }

  getOrderLink(orderId: number, isHastusPlanned: boolean, isProductBased: boolean): string {
      const base = this.isHistory ? '/history/' : '/order/';
      if (isHastusPlanned) {
        return base + 'planned-order/' + orderId;
      } else if (isProductBased) {
        return base + 'product-order/' + orderId;
      } else {
        return base + orderId;
      }
  }

  newOrder() {
    this.router.navigateByUrl('/order');
  }

  ngOnDestroy() {
    if (this.paramsub) {
      this.paramsub.unsubscribe();
    }
    if (this.stateChangedMessageSub) {
      this.stateChangedMessageSub.unsubscribe();
    }
  }
  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }

  // true om datum d ligger mer �n en vecka fram�t i tiden
  isAheadWeek(d: Date): boolean {
    const weekAhead = new Date();
    weekAhead.setDate(weekAhead.getDate() + 7);

    return d > weekAhead;
  }
}
